<template>
  <div class="wrapper">
    <menu-ver type="company" name="Company" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
          :style="{ backgroundColor: colorCompany }"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="users && users.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("mCompany.users.name") }}</th>
            <th scope="col">{{ $t("mCompany.users.user") }}</th>
            <th scope="col">{{ $t("mCompany.users.email") }}</th>
            <th scope="col">{{ $t("mCompany.users.rolTable") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.usu_id">
            <td>{{ item.usu_nombre }}</td>
            <td>{{ item.usu_usuario }}</td>
            <td>{{ item.usu_email }}</td>
            <td>
              <p v-if="item.usu_rol === 0">
                {{ $t("mCompany.users.governingBody") }}
              </p>
              <p v-else-if="item.usu_rol === 1">
                {{ $t("mCompany.users.compliance") }}
              </p>
            </td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :style="{ backgroundColor: colorCompany }"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-user">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.addTitle", { name: `${userId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName"
                v-model.trim="user.usu_nombre"
                :placeholder="$t(`mCompany.users.name`) + ' *'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.user`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="user"
                v-model.trim="user.usu_usuario"
                :placeholder="$t(`mCompany.users.user`) + '*'"
                maxlength="20"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                id="email"
                v-model.trim="user.usu_email"
                :placeholder="$t(`mCompany.users.email`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label for="FormName" class="form-label"
                >{{ $t("mCompany.users.rol") }}*</label
              >
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model="user.usu_rol"
                style="margin-bottom: 40px"
              >
                <option value="0">
                  {{ $t("mCompany.users.governingBody") }}
                </option>
                <option value="1">{{ $t("mCompany.users.compliance") }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t(`mCompany.users.signature`) }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="haveSignature"
                id="flexCheckDefault"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addUser"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.deleteTitle", { name: `${userId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("mCompany.users.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteUser"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-user">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.editTitle", { name: `${userId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormNameEdit"
                v-model.trim="user.usu_nombre"
                :placeholder="$t(`mCompany.users.name`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label style="display: block" class="form-label">{{
                $t(`mCompany.users.state`) + " *"
              }}</label>
              <input
                v-if="user.usu_bloqueado !== 0"
                style="text-transform: uppercase"
                type="text"
                class="form-control input-state"
                id="state"
                value="Bloqueado"
                disabled
              />
              <input
                v-else
                style="text-transform: uppercase"
                type="text"
                class="form-control input-state"
                id="state"
                value="No Bloqueado"
                disabled
              />
              <button
                v-if="user.usu_bloqueado !== 0"
                @click="unlockUser"
                class="btn btn-outline-secondary btn-state"
                type="button"
              >
                {{ $t("maintenance.superusers.lock") }}
              </button>
              <button
                v-else
                @click="lockUser"
                class="btn btn-outline-secondary btn-state"
                type="button"
              >
                {{ $t("maintenance.companies.lock") }}
              </button>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.user`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="userEdit"
                v-model.trim="user.usu_usuario"
                :placeholder="$t(`mCompany.users.user`) + '*'"
                maxlength="20"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                id="emailEdit"
                v-model.trim="user.usu_email"
                :placeholder="$t(`mCompany.users.email`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label for="FormName" class="form-label"
                >{{ $t("mCompany.users.rol") }}*</label
              >
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="user.usu_rol"
                style="margin-bottom: 40px"
              >
                <option value="0">
                  {{ $t("mCompany.users.governingBody") }}
                </option>
                <option value="1">{{ $t("mCompany.users.compliance") }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t(`mCompany.users.signature`) }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="haveSignature"
                id="flexCheckDefault"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editUser"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 3">
                {{ $t("errors.4") }}
              </div>
              <div class="modal-body" v-else-if="action === 4">
                {{ $t("errors.8") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 3 || action === 4"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      users: [],
      user: {
        usu_id: "",
        usu_nombre: "",
        usu_clave: "",
        usu_usuario: "",
        usu_email: "",
        usu_rol: "0",
        usu_firma: 0,
      },
      userId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
      visPas: true,
      haveSignature: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      messageError: ''
    };
  },
  created() {
    this.getUsers();
    this.changeBreadCrumb(15);
    this.bigDrop();
  },
  computed: {
    ...mapState(["colorCompany","servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getUsers() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=listado`
        );
        if (res.data) {
          this.users = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addUser() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const user = {
            usu_nombre: this.user.usu_nombre.toUpperCase(),
            usu_clave: this.user.usu_clave,
            usu_usuario: this.user.usu_usuario.toUpperCase(),
            usu_rol: this.user.usu_rol,
            usu_email: this.user.usu_email.toUpperCase(),
          };
          if (this.haveSignature) {
            user.usu_firma = "1";
          } else {
            user.usu_firma = "0";
          }
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=usu&ac=grabar`,
            user
          );
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async editUser() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          let user = {
            usu_nombre: this.user.usu_nombre.toUpperCase(),
            usu_clave: this.user.usu_clave,
            usu_usuario: this.user.usu_usuario.toUpperCase(),
            usu_rol: this.user.usu_rol,
            usu_email: this.user.usu_email.toUpperCase(),
            usu_id: this.user.usu_id,
          };
          if (this.haveSignature) {
            user.usu_firma = "1";
          } else {
            user.usu_firma = "0";
          }
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=usu&ac=actualizar`,
            user
          );
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.cleanForms();
      }
    },
    async deleteUser() {
      this.result = "";
      try {
        const borrado = {
          usu_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.cleanForms();
    },
    async unlockUser() {
      try {
        const unlock = {
          usu_id: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=desbloquear`,
          unlock
        );
        if (res.data.RESULT === "0") {
          // this.user.usu_bloqueado = 0;
          this.action = 3;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
          this.cleanForms();
        } else {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async lockUser() {
      try {
        const lock = {
          usu_id: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=bloquear`,
          lock
        );
        if (res.data.RESULT === "0") {
          // this.user.usu_bloqueado = 0;
          this.action = 4;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
          this.cleanForms();
        } else {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getId(event) {
      this.userId = event[0];
    },
    validateForm() {
      this.messageError = this.$t("errors.fieldOb");
      if (this.user.usu_nombre === "") {
        this.formError = true;
        return false;
      }
      if (this.user.usu_clave === "") {
        this.formError = true;
        return false;
      }
      if (!this.isEmailValid()) {
        this.formError = true;
        return false;
      }
      if (this.user.usu_rol === "") {
        this.formError = true;
        return false;
      }
      if (!this.validatePassword()) {
        this.formError = true;
        this.messageError = this.$t("configuration.messageError2");
        return false;
      }
      this.messageError = "";
      return true;
    },
    cleanForms() {
      this.user = {
        usu_id: "",
        usu_nombre: "",
        usu_clave: "",
        usu_usuario: "",
        usu_email: "",
        usu_rol: "0",
      };
      this.loading = false;
      this.formError = false;
      this.haveSignature = false;
      this.getUsers();
      this.messageError = '';
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.id = item.usu_id;
        this.user = item;
        if (this.user.usu_firma === 1) {
          this.haveSignature = true;
        } else {
          this.haveSignature = false;
        }
        return;
      }
      if (item.usu_id) {
        this.id = item.usu_id;
      }
    },
    isEmailValid() {
      return this.user.usu_email == ""
        ? ""
        : this.reg.test(this.user.usu_email)
        ? true
        : false;
    },
    validatePassword() {
      if (this.user.usu_clave.length >= 8) {
        let mayuscula = false;
        let minuscula = false;
        let numero = false;
        let caracter_raro = false;

        for (var i = 0; i < this.user.usu_clave.length; i++) {
          if (
            this.user.usu_clave.charCodeAt(i) >= 65 &&
            this.user.usu_clave.charCodeAt(i) <= 90
          ) {
            mayuscula = true;
          } else if (
            this.user.usu_clave.charCodeAt(i) >= 97 &&
            this.user.usu_clave.charCodeAt(i) <= 122
          ) {
            minuscula = true;
          } else if (
            this.user.usu_clave.charCodeAt(i) >= 48 &&
            this.user.usu_clave.charCodeAt(i) <= 57
          ) {
            numero = true;
          } else {
            caracter_raro = true;
          }
        }
        if (
          mayuscula == true &&
          minuscula == true &&
          caracter_raro == true &&
          numero == true
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.field-icon {
  float: right;
  margin-top: -71px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
</style>